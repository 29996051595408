import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";

import { Card } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/bi_facebook.svg";
import XLogo from "../images/logo 1.svg";
import InstaLogo from "../images/Frame 62.svg";
import ContactForm from "../components/contactForm";
import HeroOther from "../components/hero-other";

const HowToFindUsPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "find-us-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img1: wpMediaItem(title: { eq: "Pantry" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "Tanishq" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "Contact-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1: wpMediaItem(title: { eq: "Gallery Course 1 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery2: wpMediaItem(title: { eq: "Gallery Course 1 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery3: wpMediaItem(title: { eq: "Gallery Mix 1 3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4: wpMediaItem(title: { eq: "Gallery Mix 2 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5: wpMediaItem(title: { eq: "Gallery Mix 2 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6: wpMediaItem(title: { eq: "Gallery Course 3 1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7: wpMediaItem(title: { eq: "Gallery Mix 3 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			# allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
			#   nodes {
			#     localFile {
			#       childImageSharp {
			#         gatsbyImageData(
			#           formats: [AUTO, WEBP]
			#           quality: 100
			#           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			#           layout: CONSTRAINED
			#           placeholder: BLURRED
			#         )
			#       }
			#     }
			#     caption

			#     permalink
			#     timestamp
			#   }
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "How To Find Us",
				item: {
					url: `${siteUrl}/how-to-find-us`,
					id: `${siteUrl}/how-to-find-us`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="How To Find Us | Toot Hill Golf Course"
				description="Located just off the M11 and M25 this undulating parkland course is undoubtedly one of the finest in Essex."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/how-to-find-us`,
					title: "How To Find Us | Toot Hill Golf Course",
					description:
						"Located just off the M11 and M25 this undulating parkland course is undoubtedly one of the finest in Essex.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<HeroOther
						smallHeading="Where we are"
						title="How to find us"
						description={
							<>
								Located just off the M11 and M25 this undulating parkland
								<br className="d-none d-md-block" /> course is undoubtedly one
								of the finest in Essex.
							</>
						}
						externalLink
						button1Text="Reserve a Tee Time"
						button1Link="https://bookings.toothillgolfclub.co.uk/"
						button2Text="Get in touch"
						button2Link="/contact-us"
						backgroundImage={
							data.heroImg.localFile.childImageSharp.gatsbyImageData
						}
					/>

					<section className="py-5 bg-white py-lg-7">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
								<Col lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										Where is toot hill?
									</p>
									<h2 className="line-heading-dark text-center mb-5 fs-1">
										Directions to Toot Hill Golf Club
									</h2>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row className="g-5 h-lg-100">
										<Col style={{ minHeight: "100%" }} lg={4}>
											<div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
												<h3 className="text-uppercase text-white fs-4 pb-3">
													From M11
												</h3>
												<p className="text-white ">
													Leave at junction 7 Harlow and take the A414 towards
													Chelmsford. After 1.2 miles you come to a roundabout
													take the 1st exit (A414 towards Chelmsford). After 1.2
													miles you reach the Talbot roundabout go straight over
													(2nd exit). After 1.0 miles turn right into Blake Hall
													Road, after 1.1 miles turn right into Toot Hill Road.
													Continue into Toot Hill Village, just past the Green
													Man Pub take the left turn towards Stanford Rivers.
													The Golf Course is 300 yards on the right hand side.
												</p>
											</div>
										</Col>
										<Col style={{ minHeight: "100%" }} lg={4}>
											<div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
												<h3 className="text-uppercase text-white fs-4 pb-3">
													From Brentwood
												</h3>
												<p className="text-white ">
													From Wilsons Corner take the A128 towards Ongar, after
													6.6 miles you reach a mini roundabout turn left (A113)
													towards Chigwell/Stanford Rivers. After 1.5 miles turn
													right into Church Road. The Golf Course is 1.8 miles
													on the left hand side
												</p>
											</div>
										</Col>
										<Col style={{ minHeight: "100%" }} lg={4}>
											<div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
												<h3 className="text-uppercase text-white fs-4 pb-3">
													From Abridge
												</h3>
												<p className="text-white ">
													Take the A113 towards Ongar passing the Stapleford
													Airfield on the right hand side. At the Passingford
													Bridge roundabout turn left 1st exit (A113 towards
													Ongar). After 3 miles turn left into Church Road. The
													Golf Course is 1.8 miles on the left hand side.
												</p>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container>
							<Row className="">
								<Col lg={8}>
									<p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
										Address
									</p>
									<h2 className="  text-dark-green mb-5 fs-4">
										Toot Hill Golf Club, School Road, Toot Hill, Ongar, Essex,
										CM5 9PU
									</h2>
								</Col>
							</Row>

							<Row>
								<Col>
									<iframe
										className="position-relative google-map"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2473.0311425588225!2d0.1911667763615362!3d51.695875196917385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d897e47c2e754f%3A0xb7f81d8f596b4b47!2sToot%20Hill%20Golf%20Club!5e0!3m2!1sen!2suk!4v1695664623164!5m2!1sen!2suk"
										width="100%"
										style="border:0;"
										allowfullscreen=""
										loading="lazy"
										referrerpolicy="no-referrer-when-downgrade"
									></iframe>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default HowToFindUsPage;
